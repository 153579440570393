import { render, staticRenderFns } from "./Index_phone.vue?vue&type=template&id=01391c40&scoped=true&"
import script from "./Index_phone.vue?vue&type=script&lang=js&"
export * from "./Index_phone.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/common.css?vue&type=style&index=0&lang=css&"
import style1 from "../assets/css/index_index.css?vue&type=style&index=1&lang=css&"
import style2 from "./Index_phone.vue?vue&type=style&index=2&id=01391c40&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01391c40",
  null
  
)

export default component.exports