<template>
  <el-container>
    <router-view style="overflow:auto;"></router-view>
    <div class="flex-row justify-between" id="menu" style="background: rgba(255,255,255);width: 100%;">
      <div class="image-text_1 flex-col justify-between" style="margin: 5% 0 0 23%" @click="toindex">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/img/icon/index_blue.svg"/>
        <span class="text-group_2" id="index_text">首页</span>
      </div>
      <div class="image-text_2 flex-col justify-between" style="margin: 5% 23% 0 0" @click="tomy">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="../assets/img/icon/my_grey.svg"/>
        <span class="text-group_2" id="my_text">我的</span>
      </div>
    </div>
    <!--    </el-footer>-->
  </el-container>
</template>
<script>
import {next} from "lodash/seq";

export default {
  data () {
    return {
      activePath: ''
    }
  },
  created () {
    // console.log(this.$route.path)

  },
  mounted () {
    document.getElementById('menu').style.height = document.body.clientHeight * 0.12 + 'px'
    // document.getElementById('footer').style.height = document.body.clientHeight * 0.12 +'px'
    // document.getElementById('main').style.height = document.body.clientHeight * 0.88 +'px'

    if(this.$route.path === '/my'){
      this.tomy()
    }else if(this.$route.path === '/index'){
      this.toindex()
    }
  },

  watch:{
    $route(to,from){
      // console.log(from.path) // 从哪来
      // console.log(to.path) //
      if(to.path === '/my'){
        this.tomy()
      }else if(to.path === '/index'){
        this.toindex()
      }
    }
  },
  methods: {
    toindex () {
        document.getElementsByClassName('image_1')[0].setAttribute('src', require('../assets/img/icon/index_blue.svg'))
        document.getElementsByClassName('label_1')[0].setAttribute('src', require('../assets/img/icon/my_grey.svg'))
        document.getElementById('index_text').style.color = '#005eff'
        document.getElementById('my_text').style.color = 'rgba(107, 107, 107, 1)'
        if(this.$route.path !== '/index'){
          this.$router.push({
            path: 'index'
          })
        }
    },
    tomy () {
        //为了不让我的图标呈现被点击的状态！
      // if(JSON.parse(localStorage.getItem('user')).libCode === '' || JSON.parse(localStorage.getItem('user')).readerCardNum === ''){
      //   // window.location.href = process.env.VUE_APP_CARD_URL
      //   this.$router.push({
      //     path: 'bind'
      //   })
      // }else {
        document.getElementsByClassName('image_1')[0].setAttribute('src', require('../assets/img/icon/index_grey.svg'))
        document.getElementsByClassName('label_1')[0].setAttribute('src', require('../assets/img/icon/my_blue.svg'))
        document.getElementById('my_text').style.color = '#005eff'
        document.getElementById('index_text').style.color = 'rgba(107, 107, 107, 1)'
        if (this.$route.path !== '/my') {
          this.$router.push({
            path: 'my'
          })
        }
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-item {
  border-bottom: none !important;
  font-size: 1rem !important;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
}

.head {
  height: 50px;
  border: none;
  display: flex;
  justify-content: space-around;
}

#menu {
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
