<template>
  <div class="annual-summary">
    <div class="header">
      <h2>年度报告</h2>
      <span>年度借阅量:{{total}} 本</span>
    </div>
    <div class="summary-section">
      <h3>阅读徽章</h3>
      <div class="badge-container">
        <img v-for="badge in badges" :src="badge.image" :key="badge.name" class="badge">
      </div>
    </div>
    <div class="summary-section">
      <h3>分类借阅量</h3>
      <el-table :data="borrowData" style="width: 100%">
        <el-table-column prop="BookTypeName" label="分类号"></el-table-column>
        <el-table-column prop="BookTypeZH" label="分类名称"></el-table-column>
        <el-table-column prop="TotalCount" label="阅读数量"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      borrowData: [
      ],
      badges: [
        // { name: '阅读达人', image: require('@/assets/img/icon/top_one.png') },
        // { name: '借阅先锋', image: require('@/assets/img/icon/top_one.png') },
      ],
      badgesToShow: [], // 用于存储要显示的徽章
      total:0
    };
  },
  mounted() {
    this.getbooks()
  },
  methods: {
    getbooks () {
      const currentYear = new Date().getFullYear()
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL+'/sanyue/readerYearReadInfo',
        // 发送格式为json
        headers: {
          'Content-Type': 'application/json',
          'dataType': 'json',
          'Authorization': localStorage.getItem('token')
        },
        data: { Year: currentYear }
      }).then(response => {
        if (response.code === 0) { // 确保正确访问 response.data
          console.log("kkcs", response.code);
          this.borrowData = response.msg.map(book => ({
            BookTypeName: book.StrBookTypeName,
            BookTypeZH: book.StrBookTypeZH,
            TotalCount: book.TotalCount
          }));
          //获取种类的数量以及总的TotalCount
          this.calculateTotals()

        } else {
          console.log('Error:', response.msg);
        }
      }).catch(error => {
        console.error('Error fetching books:', error);
      });
    },

    // 获取总的数量和分类数量
    calculateTotals() {
      let totalBooks = 0;  // 初始化总的书籍数量
      let categoryCount = this.borrowData.length;  // 分类的数量，即数组的长度

      // 遍历 borrowData，计算总的书籍数量
      this.borrowData.forEach(book => {
        totalBooks += book.TotalCount;  // 累加每个类别的书籍数量
      });

      console.log('Total Books:', totalBooks);
      console.log('Category Count:', categoryCount);
      this.total = totalBooks;
      this.updateBadges(totalBooks)
    },

    // 更新徽章显示逻辑
    updateBadges(count) {
      const badge = { name: '阅读达人', image: require('@/assets/img/icon/win.png') };
        console.log("看看数值:"+count)
        let repeatCount = 0; // 默认值

        if (count < 10) {
          repeatCount = 1;
        } else if (count >= 10 && count < 20) {
          repeatCount = 2;
        } else if (count >= 20) {
          repeatCount = 4;
        }else if(count === 0)
        {
          repeatCount = 0
        }
        // 根据 repeatCount 循环插入相同的徽章
        for (let i = 0; i < repeatCount; i++) {
          this.badges.push(badge);
        }

    }



  }

};
</script>
<style scoped>
.annual-summary {
  padding: 10px;
}
.header {
  text-align: center;
  background-image: url("../assets/img/myreadercard.png");
  background-size: cover;
  background-position: center;
  padding: 20px 0;
  margin-bottom: 20px;
}

.summary-section {
  margin-bottom: 20px;
}

.chart {
  width: 100%;
  height: 300px;
}

.badge-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.badge {
  width: 50px;
  height: 50px;
}

@media (min-width: 768px) {
  .badge {
    width: 60px;
    height: 60px;
  }
}
</style>
